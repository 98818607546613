.ant-layout-header {
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 2px solid #f0f2f5;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    height: 64px;
}
